import { Card } from "@nextui-org/react";
import moment from "moment";

import { useCallback, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { getUser } from "../../Utils/commonUtils";
import DateComponent from "../DateComponent/DateComponent";

import debounce from "just-debounce-it";
import { MdErrorOutline } from "react-icons/md";
import { useFetch } from "../../Hooks/useFetch";
import { getAllAppointmentsByUserIdAndDate } from "../../Service/AppointmentService/AppointmentService";
import AedasLoading from "../AedasLoading/AedasLoading";

const TodayAppointments = ({
  setModalRGPD,
  setIsAppointmentSelected,
  setAppointmentPromotionId,
}: any) => {
  const [isLoading, setisLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const [appointments, isFetchingAppointments, updateAppointments, error] =
    useFetch(getAllAppointmentsByUserIdAndDate, {
      userId: getUser()?.id,
      date: date.format("YYYY-MM-DD"),
    });

  const updateAppointmentsDebounce = useCallback(
    debounce(() => {
      updateAppointments();
    }, 2000),
    []
  );

  useEffect(() => {
    updateAppointmentsDebounce();
  }, [date, updateAppointmentsDebounce]);

  useEffect(() => {
    if (!isFetchingAppointments) {
      setisLoading(false);
    }
  }, [isFetchingAppointments]);

  const handleAdd = () => {
    setisLoading(true);
    setDate(moment(date).add("day", 1));
  };

  const handleSubtract = () => {
    setisLoading(true);
    setDate(moment(date).subtract("day", 1));
  };

  return (
    <Card css={{ width: "100%", height: "100%", padding: "0.8rem" }}>
      <div style={{ padding: "1rem 0.5rem", zIndex: 10 }}>
        <h1
          style={{
            textAlign: "center",
            fontFamily: "lis-title",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          Citas
        </h1>
      </div>
      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          justifyContent: "center",
          zIndex: "10",
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0.5rem",
            width: "80%",
          }}
          variant="bordered"
        >
          <div
            onClick={() => {
              handleSubtract();
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <FiChevronLeft size={24} color={"black"}></FiChevronLeft>
          </div>
          <div>{date.format("DD-MM-YYYY")}</div>
          <div
            onClick={handleAdd}
            style={{
              cursor: "pointer",
            }}
          >
            <FiChevronRight size={24}></FiChevronRight>
          </div>
        </Card>
      </div>
      {isFetchingAppointments || isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "250px" }}
        >
          <AedasLoading></AedasLoading>
        </div>
      ) : (
        <div
          style={{
            padding: "5px",
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {appointments && appointments?.length > 0 && !error ? (
            appointments
              ?.sort((a: any, b: any) => {
                //@ts-ignore
                if (moment(a.dateInitial).isAfter(moment(b.dateInitial))) {
                  return 1;
                } else if (
                  moment(a.dateInitial).isBefore(moment(b.dateInitial))
                ) {
                  return -1;
                }
                return 0;
              })
              //Comentar este filter si quieres permitir ver citas anteriores
              // ?.filter((a: any) =>
              //   moment(a.dateInitial).isAfter(moment().subtract(12, "hours"))
              // )
              ?.map((a: any, i: any) => {
                // Transformar la zona horaria a la de Madrid, en este caso es +00, pasarla a +2 de Madrid
                const date = moment.utc(a.dateInitial);
                date.tz("Europe/Madrid");

                return (
                  <DateComponent
                    key={`${i}-${a.name_client}-${a.dateInitial}`}
                    date={date.format()}
                    client={a.name_client}
                    promotion={a.name_promotion}
                    id={a.id}
                    setModalRGPD={setModalRGPD}
                    RGPD={a.is_rgdp_signed}
                    setIsAppointmentSelected={setIsAppointmentSelected}
                    setAppointmentPromotionId={setAppointmentPromotionId}
                  ></DateComponent>
                );
              })
          ) : (
            <span className="font-family-lis d-flex align-items-center gap-1 justify-content-center">
              <MdErrorOutline size={24} color={"#666"} /> No hay citas para este
              día
            </span>
          )}
        </div>
      )}
    </Card>
  );
};

export default TodayAppointments;
