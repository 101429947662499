import { Loading } from "@nextui-org/react";
import { useHistory, useParams } from "react-router-dom";
import { setDurationByAppointmentId } from "../../Service/AppointmentDuration/AppointmentDuration";
import { useEffect, useState } from "react";
import { sendDocumentation } from "../../Service/EmailService/EmailService";
import { SignBody } from "./SignBody";
import { getLikesService } from "../../Service/LikesService/LikesService";
import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import toast from "react-hot-toast";
import { ThirdPartModalBody } from "./ThirdPartModalBody";
import { getDocumentationNew } from "../../Service/DocumentsService/DocumentsService";

export default function ThirdPartAppoinmentEndModal(props: any) {
  const {
    mainImage,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    formikTask,
    sendJson,
    planosFavoritos,
    setSelectedStep,
    informacionViviendas,
    setTipRelationalStyle,
    documentacion,
    setDocumentationLanguage,
    documentationLanguage,
    DowloadDocumentation,
    isLoadingDocumentation,
    showLanguagesOptions,
    setShowLanguagesOptions,
    downloadBase64File,
    setDocumentacion,
  } = props;

  const { id_appoiment }: any = useParams();

  const [sendEmail, setSendEmail] = useState(false);
  const [newEmail, setNewEmail] = useState(client.email);
  const [error, setError] = useState(false);
  const [planosAEnviar, setPlanosAEnviar] = useState(planosFavoritos);
  const [pointHouse, setPointHouse] = useState();
  const [canFinishVisit, setCanFinishVisit] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [openModalSign, setOpenModalSign] = useState(false);
  const [isDIA, setIsDIA] = useState(false);
  const [houseId, setHouseId] = useState();
  const [assetId, setAssetId] = useState();
  const [rerenderlikes, setRerenderlikes] = useState(false);
  const [planosFavs, setPlanosFavs] = useState(planosFavoritos);
  const [infoViviendas, setInfoViviendas] = useState(informacionViviendas);
  const [loadingLikes, setLoadingLikes] = useState(true);
  const [loadingDocumentsToSign, setLoadingDocumentsToSign] = useState(false);
  const [documentsSigned, setDocumentsSigned] = useState(true);
  const [idPromo, setIdPromo] = useState();

  useEffect(() => {
    // Si quieren enviar el correo, no puede haber error
    sendEmail && error && setCanFinishVisit(false);
    sendEmail && !error && setCanFinishVisit(true);
    //Si no quieren enviar el correo les dejaremos siempre finalizar la visita
    !sendEmail && setCanFinishVisit(true);
  }, [sendEmail, error]);

  useEffect(() => {
    setLoadingLikes(true);
    getLikesService(client.id, promotion.id)
      .then((response: any) => {
        setPlanosFavs(response.data.planos);
        setLoadingLikes(false);
      })
      .catch((err) =>
        toast.error(
          "Ha habido un error a la hora de cargar las viviendas favoritas"
        )
      );
  }, [rerenderlikes]);

  useEffect(() => {
    const allDocumentsSigned = planosFavs.every(
      (plano: any) => plano.dia_signed && plano.dia_proof_signed
    );
    setDocumentsSigned(allDocumentsSigned);
  }, [planosFavs]);

  useEffect(() => {
    if (!documentacion) {
      setLoadingDocumentsToSign(true);
      //limpiar objeto de los planos favoritos para mandar solo lo necesario
      const likes = planosFavoritos.map((plano: any) => {
        return {
          asset_id: plano.asset_id,
          generate_file: plano.generate_file,
          asset_name: plano.asset_name,
          url: plano.url,
          id_promo: plano.id_promo,
          id_house: plano.id_house,
        };
      });
      if (promotion?.id && !documentacion) {
        getDocumentationNew(
          client.id,
          promotion?.id,
          id_appoiment,
          "es",
          likes
        ).then((response: any) => {
          //se guardan todos los campos porque hacen falta para otros servicios (DIA)
          setDocumentacion(response.data);
          setLoadingDocumentsToSign(false);
          setShowLanguagesOptions(false);
        });
      }
    }
  }, []);

  // Obtenemos la información de las casas a través del ID de los planos.
  useEffect(() => {
    const assetIds = planosFavs?.map((plano: any) => plano.asset_id);
    if (assetIds && assetIds.length > 0) {
      getHouseInfoByAssets(assetIds).then((response: any) => {
        setInfoViviendas(response.data);
      });
    }
  }, [planosFavs]);

  const history = useHistory();

  const handleSendingData = () => {
    setSendingData(true);
    sendJson({
      event: "gracias_por_tu_visita",
      value: "gracias_por_tu_visita",
    });

    setDurationByAppointmentId(
      id_appoiment,
      `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }:${seconds < 10 ? "0" + seconds : seconds}`,
      true,
      "",
      formikTask.values.contactoagendado,
      formikTask.values.emailenviado,
      formikTask.values.informacionsolicitada
    );

    formik.submitForm().then(() => {
      //@ts-ignore
      if (formik.values.send_email) {
        // Cuando estamos en PROD llega al cliente
        if (process.env.REACT_APP_IS_PRO === "true") {
          sendDocumentation(
            id_appoiment,
            planosAEnviar,
            newEmail,
            documentationLanguage,
            client.id
          );
        } else {
          // Si está en DEV/UAT que llegua siempre a la cuenta de Jorge "jlvalero@aedashomes.com"
          sendDocumentation(
            id_appoiment,
            planosAEnviar,
            process.env.REACT_APP_EMAIL_ADMIN,
            documentationLanguage,
            client.id
          );
        }
      }

      setSendingData(false);

      sessionStorage.removeItem("stepper_aedas");
      history.push("/");
    });
  };

  if (sendingData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          height: "100%",
          minHeight: "350px",
        }}
      >
        <span style={{ fontSize: "1.5rem", fontFamily: "lis-title" }}>
          Guardando los datos de la visita
        </span>{" "}
        <Loading type="points" size="xl"></Loading>
      </div>
    );
  }

  if (openModalSign) {
    return (
      <SignBody
        client={client}
        setOpenModalSign={setOpenModalSign}
        isDIA={isDIA}
        houseId={houseId}
        assetId={assetId}
        setRerenderlikes={setRerenderlikes}
        rerenderlikes={rerenderlikes}
        documentacion={documentacion}
        idPromo={idPromo}
      ></SignBody>
    );
  } else {
    if (loadingDocumentsToSign) {
      return (
        <div
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Cargando la documentación necesaria...
        </div>
      );
    }
    return (
      <ThirdPartModalBody
        client={client}
        mainImage={mainImage}
        promotion={promotion}
        formik={formik}
        setTipRelationalStyle={setTipRelationalStyle}
        setSendEmail={setSendEmail}
        sendEmail={sendEmail}
        setNewEmail={setNewEmail}
        setError={setError}
        newEmail={newEmail}
        error={error}
        setDocumentationLanguage={setDocumentationLanguage}
        documentationLanguage={documentationLanguage}
        loadingLikes={loadingLikes}
        planosFavs={planosFavs}
        handleSendingData={handleSendingData}
        infoViviendas={infoViviendas}
        sendJson={sendJson}
        pointHouse={pointHouse}
        setPointHouse={setPointHouse}
        planosAEnviar={planosAEnviar}
        setPlanosAEnviar={setPlanosAEnviar}
        setOpenModalSign={setOpenModalSign}
        setIsDIA={setIsDIA}
        setHouseId={setHouseId}
        setAssetId={setAssetId}
        isLoadingDocumentation={isLoadingDocumentation}
        showLanguagesOptions={showLanguagesOptions}
        setShowLanguagesOptions={setShowLanguagesOptions}
        DowloadDocumentation={DowloadDocumentation}
        documentacion={documentacion}
        downloadBase64File={downloadBase64File}
        canFinishVisit={canFinishVisit}
        setSelectedStep={setSelectedStep}
        documentsSigned={documentsSigned}
        setIdPromo={setIdPromo}
      ></ThirdPartModalBody>
    );
  }
}
