import { Card, Loading } from "@nextui-org/react";
import moment from "moment";
import "moment/min/locales";
import { useCallback, useEffect, useState } from "react";
import { GoCalendar, GoLocation } from "react-icons/go";
import { useParams } from "react-router-dom";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import Budget from "../../Components/ClientViews/Budget";
import ClientViews from "../../Components/ClientViews/ClientViews";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getClientByAppointmentId,
  getPromoByAppointmentId,
} from "../../Service/AppointmentService/AppointmentService";
import { getAllAssetsByPromo } from "../../Service/AssetsService/AssetsService";
import { getWeatherByCoords } from "../../Service/WeatherServices/WeatherServices";
import { getWeatherIconByCode } from "../../Utils/weatherUtils";

import {
  AiOutlineFullscreen,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import ClientViewInfo from "../../Components/ClientInfo/ClientInfo";
import CalendarPayment from "../../Components/ClientViews/CalendarPayment";
import { getPromotionByID } from "../../Service/PromotionService/PromotionService";
import ThanksForYourVisit from "../ThanksForYourVisit/ThanksForYourVisit";
import "./Client.css";

function Client() {
  const { id, id_appoiment }: any = useParams();
  const [assets, setAssets] = useState(null);
  const [selectedValue, setselectedValue] = useState("");
  const [Index, setIndex] = useState(1);
  const [weatherCode, setWeatherCode] = useState<any>();
  const [coords, setCoords] = useState<any>(null);
  const [promotionID, setPromotionID] = useState();
  const [promotion, setPromotion] = useState<any>();
  const [knowMoreAboutYou, setKnowMoreAboutYou] = useState(true);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  const [endOfVisit, setEndOfVisit] = useState(false);
  const [thanksForYourVisit, setThanksForYourVisit] = useState(false);

  const [isFetchingAssets, setIsFetchingAssets] = useState(true);

  const [displayBudget, setDisplayBudget] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [displayPlane, setDisplayPlane] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState(""); // Usado para marcar en la pantalla de cliente la casa que ha marcado el comercial.
  const [timezone] = useState<string>(
    JSON.parse(localStorage.getItem("timezone") || "null") || "UTC"
  );

  const [fechaString, setFechaString] = useState<string>("");

  useEffect(() => {
    const fechaModificada =
      timezone === "Canarias"
        ? moment.tz(moment(), "Atlantic/Canary").format("HH:mm")
        : moment.tz(moment(), "Europe/Madrid").format("HH:mm");

    setFechaString(fechaModificada);
  }, [timezone]);
  const [viviendasFavoritas, setViviendasFavoritas] = useState();

  const [defaultPromotion] = useFetch(getPromoByAppointmentId, id_appoiment);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoords(position.coords);
    });
    // Redimensionamos la pantalla al 100%
    window.resizeTo(window.screen.availWidth, window.screen.availHeight);

    // Si queremos ver el calendario o el prespuesto seteamos a false el mensaje para poder mostrar el calendario o el presupuesto.
    if (displayBudget || displayCalendar || selectedHouse) {
      setKnowMoreAboutYou(false);
    }
  }, []);

  useEffect(() => {
    setPromotion(defaultPromotion);
  }, [defaultPromotion]);

  useEffect(() => {
    if (promotionID) {
      getPromotionByID(promotionID).then(setPromotion);
    }
  }, [promotionID]);

  const [client, isFetchingClient] = useFetch(
    getClientByAppointmentId,
    id_appoiment
  );

  useEffect(() => {
    if (promotion) {
      setIsFetchingAssets(true);
      getAllAssetsByPromo(promotion?.id)
        .then((response: any) => {
          setAssets(response.data);
        })
        .finally(() => {
          setIsFetchingAssets(false);
        });
    } else {
      setIsFetchingAssets(false);
    }
  }, [promotion]);

  useEffect(() => {
    if (coords) {
      getWeatherByCoords(coords.latitude, coords.longitude).then((response) => {
        setWeatherCode(response.data.current_weather.weathercode);
      });
    }
  }, [coords]);

  useEffect(() => {
    if (displayBudget || displayCalendar || selectedHouse || displayPlane) {
      setKnowMoreAboutYou(false);
    }
  }, [displayBudget, displayCalendar, selectedHouse]);

  const [plano, setPlano] = useState(null);
  const [principalProduct, setPrincipalProduct] = useState([]);
  const [messageProduct, setMessageProduct] = useState(null);
  const [tax, setTax] = useState(null);
  const [documentsToShow, setDocumentsToShow] = useState(null);

  const [clientId, setClientId] = useState(null);

  const [ws, setWs] = useState<any>();
  const [ConnectToServer, setConnectToServer] = useState(false);

  const connectWebSocket = useCallback(() => {
    const socket: any = new WebSocket(process.env.REACT_APP_WS + id);

    socket.onclose = () => {
      setConnectToServer(true);
      // WebSocket cerrado, intentar reconexión
      setTimeout(() => {
        setConnectToServer(false);
        connectWebSocket();
      }, 2000); // Esperar 2 segundos antes de intentar la reconexión
    };

    setWs(socket);
  }, [id]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const [Values, setValues] = useState<any>({
    Como__c: null,
    Codigo_Postal_Actual__pc: null,
    Profesion__c: null,
    profesionConyuge2: null,
    Empresa_cliente__pc: null,
    Empresa_De_Conyugue_2__pc: null,

    // Info Cliente --> Información socioeconómica
    Tipo_de_unidad_familiar__pc: null,
    Miembros_en_la_unidad_familiar__pc: 0, // ¿Cuántos miembros tiene la familia?
    Numero_de_hijos__c: null,
    Nombre_hijo_hija_1__c: null,
    Nombre_hijo_hija_2__c: null,
    Nombre_hijo_hija_3__c: null,
    Nombre_hijo_hija_4__c: null,
    Nombre_hijo_hija_5__c: null,
    Nombre_hijo_hija_6__c: null,
    Edad_hijo_hija_1__c: null,
    Edad_hijo_hija_2__c: null,
    Edad_hijo_hija_3__c: null,
    Edad_hijo_hija_4__c: null,
    Edad_hijo_hija_5__c: null,
    Edad_hijo_hija_6__c: null,
    Ocupacion__c: null, //A qué te dedicas
    Vivienda_actual_en_prop__pc: null,
    Vive_de_alquiler__c: "No",
    Con_quien_compra__c: null,
    aficiones: null, //¿Cuáles son tus aficiones?
    que_busca: null, // ¿Qué es lo que estás buscando?

    // Info Cliente --> Vivienda buscada
    Dormitorios__c: null, //Numero Habitacines
    Vivienda_buscada__c: null,
    //de_financiaci_n_necesario__c: null,
    Garajes_necesarios__c: null,
    DestinoVivienda__c: null, //Motivo de la compra

    // Info Cliente --> Otros

    // ** End: Tab Info Cliente **

    // ** Start: Tab Show **
    // Show --> Ubicación/Sector

    // Mapa
    //conoceLaZona: null,
    //transportePublicoEnLaZona: null,
    //comercios: null,
    //colegios: null,
    //zonasDeportivas: null,

    // Parcela
    //orientacion: null,
    //construccion: null,
    //alturaDelEdificio: null,
    //arquitecturaYDiseno: null,

    // Maqueta virtual
    //cercaniaAlMar: null,
    // orientacion: null,
    // construccion: null,

    // Show --> Proyecto exterior
    distribucionDeBloques: null,
    zonasVerdes: null,
    accesoVehiculosPeatonal: null,
    disenoDelEdificio: null,
    cualidadesArquitectonicas: null,
    acabadosExteriores: null,

    // Show --> Zonas comunes
    piscina: null,
    pistaPolideportiva: null,
    diseno: null,
    salaComunitariaMultiusos: null,
    modoNoche: null,
    dimensiones: null,
    recorridoConexionZonas: null,

    // Show --> Personalizar calidades
    tipoDeAcabado: null,
    sueloRadianteYRefrescante: null,
    aerotermia: null,
    aislamientosTermicosYAcusticos: null,

    // Show --> Piso piloto
    cocinaAmueblada: null,
    bano: null,
    armariosEmpotrados: null,
    cocinaAbierta: null,
    // aerotermia: null,
    // ** End: Tab Show **

    // ** Start: Tab Pago **
    Presupuesto__c: null, //Presupuesto_orientativo_del_cliente__c
    PerFinanciacionNecesario__c: null, //Porcentaje_De_Financiacion_Necesario__c
    Plazo_de_entrega_deseado__c: null, //planzo de entrega
    // ** End: Tab Pago **

    // Varios datos "desperdigados" por la pantalla o modales
    tiempoVisita: null,
    Arquetipo__c: null, // Arquetipo
    relationalStyle: null, // Estilo Relacional
    valorationDegree: null, // Valorar cita
    send_email: null, // Finalizar cita
  });

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {};

      ws.onmessage = (event: any) => {
        const data = JSON.parse(event.data);

        if (data?.event === "change_clientview") {
          if (data?.value === "plano") {
            setDisplayBudget(false);
            setDisplayCalendar(false);
            setPlano(data.url);
          } else if (data?.value === "cargando_listado_viviendas") {
            setMessageProduct(data.message);
          } else if (data?.value === "listado_viviendas") {
            if (data.list) {
              setPrincipalProduct(data.list?.asset);
              setTax(data.list?.tax);
            }
          } else if (data?.value === "showDocuments") {
            if (data.list) {
              setDocumentsToShow(data.list);
            }
          }
          setselectedValue(data.value);
        } else if (data?.event === "go_index") {
          setIndex(data.value);
        } else if (data?.event === "changePromotion") {
          setPromotionID(data?.value);
        } else if (data?.event === "change_form") {
          setValues(data?.value);
        } else if (data?.event === "fin_de_la_cita") {
          setEndOfVisit(data?.value);
        } else if (data?.event === "gracias_por_tu_visita") {
          setThanksForYourVisit(true);
        } else if (data?.event === "mostrar_presupuesto") {
          setDisplayBudget(data?.value);
          setDisplayCalendar(false);
          setDisplayPlane(false);
          setSelectedHouse(data?.selectedHouse);
        } else if (data?.event === "mostrar_calendario") {
          setDisplayCalendar(data?.value);
          setDisplayBudget(false);
          setDisplayPlane(false);
          setSelectedHouse(data?.selectedHouse);
        } else if (data?.event === "mostrar_viviendas_favoritas") {
          setViviendasFavoritas(data?.list);
          setselectedValue(data?.event);
          setPromotionID(data?.promotionId);
          setClientId(data?.clientId);
        }
      };
    }
  }, [ws]);

  /**
   * @description Función que se pasa al componente de Favorites para poder controlar que se muestre el calendario de pagos desde la propia ventana de cliente.
   * @param {boolean} calendarFlag Estado de displayCalendar
   * @param {string} house_id
   */
  const functionToDisplayCalendar = (calendarFlag: any, house_id: any) => {
    setDisplayCalendar(calendarFlag);
    setDisplayBudget(false);
    setSelectedHouse(house_id);
  };

  /**
   * @description Función que se pasa al componente de Favorites para poder controlar que se muestre el presupuesto.
   * @param {boolean} budgetFlag Estado de displayBudget
   * @param {string} house_id
   */
  const functionToDisplayBudget = (budgetFlag: any, house_id: any) => {
    setDisplayCalendar(false);
    setDisplayBudget(budgetFlag);
    setSelectedHouse(house_id);
  };

  useEffect(() => {
    Values?.Como__c && setKnowMoreAboutYou(false);
    Values?.DestinoVivienda__c && setKnowMoreAboutYou(false);
    Values?.Miembros_en_la_unidad_familiar__pc && setKnowMoreAboutYou(false);
    Values?.que_busca && setKnowMoreAboutYou(false);
    Number(Values?.Dormitorios__c) > 0 && setKnowMoreAboutYou(false);
    Values?.Plazo_de_entrega_deseado__c && setKnowMoreAboutYou(false);
    Values?.que_busca && setKnowMoreAboutYou(false);
    Values?.Presupuesto__c &&
      (Number(Values?.Presupuesto__c[0]) > 0 ||
        (Number(Values?.Presupuesto__c[0]) === 0 &&
          Number(Values?.Presupuesto__c[1]) < 3000000)) &&
      setKnowMoreAboutYou(false);
    Values?.PerFinanciacionNecesario__c &&
      (Number(Values?.PerFinanciacionNecesario__c[0]) > 0 ||
        (Number(Values?.PerFinanciacionNecesario__c[0]) === 0 &&
          Number(Values?.PerFinanciacionNecesario__c[1]) < 100)) &&
      setKnowMoreAboutYou(false);

    // Control para mostrar el texto de "¡Necesitamos saber más de ti..."
    if (
      !Values?.Como__c &&
      !Values?.DestinoVivienda__c &&
      !Values?.Miembros_en_la_unidad_familiar__pc &&
      !Values?.que_busca &&
      Number(Values?.Dormitorios__c) === 0 &&
      !Values?.Plazo_de_entrega_deseado__c &&
      !Values?.que_busca &&
      (!Values?.Presupuesto__c ||
        (Number(Values?.Presupuesto__c[0]) === 0 &&
          Number(Values?.Presupuesto__c[1]) === 3000000)) &&
      (!Values?.PerFinanciacionNecesario__c ||
        (Number(Values?.PerFinanciacionNecesario__c[0]) === 0 &&
          Number(Values?.PerFinanciacionNecesario__c[1]) === 100))
    ) {
      setKnowMoreAboutYou(true);
    }

    if (
      Values?.Como__c &&
      Values?.DestinoVivienda__c &&
      Values?.Miembros_en_la_unidad_familiar__pc &&
      Values?.que_busca &&
      Number(Values?.Dormitorios__c) > 0 &&
      Values?.Plazo_de_entrega_deseado__c &&
      Values?.que_busca &&
      Values?.Presupuesto__c &&
      (Number(Values?.Presupuesto__c[0]) > 0 ||
        (Number(Values?.Presupuesto__c[0]) === 0 &&
          Number(Values?.Presupuesto__c[1]) < 3000000)) &&
      Values?.PerFinanciacionNecesario__c &&
      (Number(Values?.PerFinanciacionNecesario__c[0]) > 0 ||
        (Number(Values?.PerFinanciacionNecesario__c[0]) === 0 &&
          Number(Values?.PerFinanciacionNecesario__c[1]) < 100))
    ) {
      setAllQuestionsAnswered(true);
    } else {
      setAllQuestionsAnswered(false);
    }
  }, [Values]);

  /** Ini: Controles para revisar si un campo ha sido actualizado desde la ventana de cliente **/

  /** Fin: Controles para revisar si un campo ha sido actualizado desde la ventana de cliente **/
  const isLoading = useIsLoading([isFetchingAssets, isFetchingClient]);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  if (thanksForYourVisit) {
    return <ThanksForYourVisit />;
  }

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="client-view" style={{ display: "flex", height: "100%" }}>
      {ConnectToServer ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
            background: "rgb(255 255 255 / 65%)",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <span>Reconectando con el servidor</span>
          <Loading></Loading>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          width: "70%",
          height: "100vh",
          padding: "1rem 0rem 1rem 1rem",
        }}
      >
        {/* Verificar si todos los elementos dentro de assets están vacíos */}
        {assets !== null &&
        Object.values(assets).every((element: any) => element.length === 0) ? (
          <p>Todos los recursos están vacíos.</p>
        ) : (
          <Card style={{ height: "100%", backgroundColor: "#ffffff40" }}>
            <ClientViews
              iews
              value={selectedValue}
              Index={Index}
              assets={assets}
              plano={plano}
              documentsToShow={documentsToShow}
              messageProduct={messageProduct}
              principalProduct={principalProduct}
              selectedHouse={selectedHouse}
              tax={tax}
              clientId={clientId}
              likedElements={viviendasFavoritas}
              promotionId={promotionID}
              functionToDisplayCalendar={functionToDisplayCalendar}
              functionToDisplayBudget={functionToDisplayBudget}
            ></ClientViews>
          </Card>
        )}
      </div>

      <div
        style={{
          width: "30%",
          height: "100vh",
          padding: "1rem",
        }}
      >
        <Card style={{ height: "75px" }}>
          <div
            style={{ position: "relative", maxHeight: "75px" }}
            onClick={handleFullScreen}
          >
            <div
              style={{
                position: "absolute",
                background: "white",
                width: "100%",
                height: "100%",
                opacity: "0.75",
                padding: "10px",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ height: "auto", marginTop: "0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <GoLocation></GoLocation>
                    <p style={{ margin: "0px", lineHeight: "1" }}>
                      {promotion?.name} -
                    </p>

                    <p style={{ margin: "0px", lineHeight: "1" }}>
                      {" "}
                      {promotion?.address}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <GoCalendar></GoCalendar>
                    <p style={{ margin: "0px", lineHeight: "1" }}>
                      {fechaString}
                    </p>
                  </div>
                </div>
                <div style={{ height: "auto" }}>
                  {getWeatherIconByCode(weatherCode)}
                </div>
              </div>
            </div>
            <img
              alt="Promoción"
              style={{ objectFit: "cover", top: "200px" }}
              src={"/Assets/img/promocion.jpg"}
            ></img>
          </div>
        </Card>
        <Card
          style={{
            padding: "0.8rem",
            marginTop: "1rem",
            height: "calc(100% - 91px)", //75px de la caja - 16px de 1rem
            backgroundColor: "#ffffff40",
          }}
        >
          <div className="d-flex justify-content-between">
            <h2 style={{ fontSize: "20px", marginBottom: "0px" }}>
              {client.displayName}
            </h2>

            <AiOutlineFullscreen
              className="hover-pointer"
              onClick={handleFullScreen}
              size={24}
            />
          </div>
          {endOfVisit && (
            <div className="client-info mt-2">
              <h1
                style={{
                  textAlign: "start",
                  fontFamily: "lis-body",
                  fontSize: "16px",
                  display: "flex",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <AiOutlineMail></AiOutlineMail>
                </div>

                {client.email ?? "-"}
              </h1>
              <h1
                style={{
                  textAlign: "start",
                  fontFamily: "lis-body",
                  fontSize: "16px",
                  display: "flex",
                  marginBottom: "0px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <AiOutlinePhone size={15}></AiOutlinePhone>
                </div>

                {client.phone ?? "-"}
              </h1>
            </div>
          )}
          <div
            style={{
              border: "1px solid #eaeaea",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          ></div>
          {knowMoreAboutYou ? (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <p style={{ fontSize: "25px", textAlign: "center" }}>
                ¡Necesitamos saber más de ti{" "}
                <span style={{ fontWeight: "bold" }}>
                  para poder ofrecerte la vivienda de tus sueños!
                </span>
              </p>
            </div>
          ) : (
            <div className="h-100">
              {displayBudget && <Budget selectedHouse={selectedHouse} />}
              {displayCalendar && (
                <CalendarPayment selectedHouse={selectedHouse} />
              )}
              {!displayBudget && !displayCalendar && (
                <ClientViewInfo
                  Values={Values}
                  allQuestionsAnswered={allQuestionsAnswered}
                ></ClientViewInfo>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}

export default Client;
